const OpeninghoursModel = (json, lang, translate) => {
    let data = [];
    if (json.establishment_timetables.length > 0) {
        let exception_days = []
        let dayVariables = {
            monday: "",
            tuesday: "",
            wednesday: "",
            thursday: "",
            friday: "",
            saturday: "",
            sunday: "",
        }
        let monday = ""
        let tuesday = ""
        let wednesday = ""
        let thursday = ""
        let friday = ""
        let saturday = ""
        let sunday = ""

        json.establishment_timetables.forEach(timetable => {
            let item = timetable.timetable
            let check_record = (x) => {
                let oh = {
                    hours: [],
                    items: [],
                    message: [],
                    desciption: '',
                    is_closed: false,
                    appointment_only: false
                }
                if (x === 'closed' || x.closed) {
                    oh.is_closed = true;
                    if (x.message) {
                        oh.message = [{ title: "", message: x.message }]
                    }
                }
                if (x !== 'closed' && x.timeslots && x.timeslots.length > 0) {
                    x.timeslots.forEach((timeslot) => {
                        if (timeslot.message && timeslot.message.message && x.timeslots.length > 1) {
                            let y = {
                                from: timeslot.from_time,
                                to: timeslot.to_time,
                                title: '',
                                desc: '',
                                link: ''
                            }
                            y.title = timeslot.message.title ? timeslot.message.title : ''
                            y.desc = timeslot.message.message ? timeslot.message.message : ''
                            oh.items.push(y)

                        }
                        else if (timeslot.message && timeslot.message.message) {
                            let y = {
                                from: timeslot.from_time,
                                to: timeslot.to_time,
                                title: '',
                                desc: '',
                                link: ''
                            }
                            y.title = timeslot.message.title ? timeslot.message.title : ''
                            y.desc = timeslot.message.message ? timeslot.message.message : ''
                            oh.items.push(y)
                            oh.hours.push(y)
                        }
                        else {
                            oh.hours.push({ from: timeslot.from_time, to: timeslot.to_time, title: timeslot.message ? timeslot.message.title : '', desc: timeslot.message ? timeslot.message.message : '' })
                            oh.message = x.messages
                        }

                    })
                }
                else if (x !== 'closed' && !x.closed && x.exception_timeslots && x.exception_timeslots.length > 0) {
                    if (x.message) {
                        oh.message = [{ title: "", message: x.message }]
                    }
                    x.exception_timeslots.reverse().forEach((timeslot) => {
                        if (timeslot.message && timeslot.message.message && x.exception_timeslots.length > 1) {
                            let y = {
                                from: timeslot.from_time,
                                to: timeslot.to_time,
                                title: '',
                                desc: '',
                                link: ''
                            }
                            y.title = timeslot.message.title ? timeslot.message.title : ''
                            y.desc = timeslot.message.message ? timeslot.message.message : ''
                            oh.items.push(y)
                            oh.hours.push(y)

                        }
                        else if (timeslot.message && timeslot.message.message) {
                            let y = {
                                from: timeslot.from_time,
                                to: timeslot.to_time,
                                title: '',
                                desc: '',
                                link: ''
                            }
                            y.title = timeslot.message.title ? timeslot.message.title : ''
                            y.desc = timeslot.message.message ? timeslot.message.message : ''
                            oh.items.push(y)
                            oh.hours.push(y)
                        }
                        else {
                            oh.hours.push({ from: timeslot.from_time, to: timeslot.to_time, title: '', desc: '' })
                            if (x.message) {
                                oh.message = [{ title: "", message: x.message }]
                            }
                            // oh.message = x.messages
                        }
                    })
                }
                else if (!x.closed) {
                    oh.message = x.message && x.message.descriptions.map(message => (
                        message.language === lang ? message.description : ''
                    ))
                }
                return oh
            }

            let default_record = ""

            item.records.forEach((record) => {
                for (let i = 0; i < record.valid_days.length; i++) {
                    let day = record.valid_days[i]
                    if (day === '0') { default_record = record.timeslots.length > 0 ? check_record(record) : check_record('closed') }
                    if (day === '1') { dayVariables.monday = record.timeslots.length > 0 ? check_record(record) : check_record('closed') }
                    if (day === '2') { dayVariables.tuesday = record.timeslots.length > 0 ? check_record(record) : check_record('closed') }
                    if (day === '3') { dayVariables.wednesday = record.timeslots.length > 0 ? check_record(record) : check_record('closed') }
                    if (day === '4') { dayVariables.thursday = record.timeslots.length > 0 ? check_record(record) : check_record('closed') }
                    if (day === '5') { dayVariables.friday = record.timeslots.length > 0 ? check_record(record) : check_record('closed') }
                    if (day === '6') { dayVariables.saturday = record.timeslots.length > 0 ? check_record(record) : check_record('closed') }
                    if (day === '7') { dayVariables.sunday = record.timeslots.length > 0 ? check_record(record) : check_record('closed') }

                }
            })
            let timetable_title = ''
            if (item.timetable_type) {
                timetable_title = item.timetable_type.title
            } else { timetable_title = translate('opening_hours', lang) }

            let record_24_7 = {
                "hours": [
                    {
                        "from": "00:00:00",
                        "to": "23:59:59"
                    }
                ],
                "items": [],
                "message": null,
                "desciption": "",
                "is_closed": false,
                "appointment_only": false
            }

            item.day_exception.map((exception) => {
                function getEasterDate(year) {
                    // This implementation of the algorithm to calculate the date of Easter in Western Christianity is based on the Meeus/Jones/Butcher algorithm
                    // Source: https://en.wikipedia.org/wiki/Computus#Anonymous_Gregorian_algorithm
                    let a = year % 19;
                    let b = Math.floor(year / 100);
                    let c = year % 100;
                    let d = Math.floor(b / 4);
                    let e = b % 4;
                    let f = Math.floor((b + 8) / 25);
                    let g = Math.floor((b - f + 1) / 3);
                    let h = (19 * a + b - d - g + 15) % 30;
                    let i = Math.floor(c / 4);
                    let k = c % 4;
                    let L = (32 + 2 * e + 2 * i - h - k) % 7;
                    let m = Math.floor((a + 11 * h + 22 * L) / 451);
                    let month = Math.floor((h + L - 7 * m + 114) / 31);
                    let day = ((h + L - 7 * m + 114) % 31) + 1;

                    return new Date(year, month - 1, day);
                }

                function addDaysToDate(date, days) {
                    let newDate = new Date(date);
                    newDate.setDate(newDate.getDate() + days);
                    return newDate;
                }
                const today = new Date();
                let year = today.getFullYear();
                let easterDate = getEasterDate(year)
                let holidays = {
                    'EASTER': getEasterDate(year),
                    'NEW_YEAR': new Date(year, 0, 1),
                    'EASTER_MONDAY': addDaysToDate(easterDate, 1),
                    'LABOUR_DAY': new Date(year, 4, 1),
                    'ASCENSION_DAY': addDaysToDate(easterDate, 39),
                    'WHIT_MONDAY': addDaysToDate(easterDate, 50),
                    'PENTECOST': addDaysToDate(easterDate, 49),
                    'NATIONAL_HOLLIDAY': new Date(year, 6, 21),
                    'ASSUMPTION_OF_MARY': new Date(year, 7, 15),
                    'ALL_SAINTS_DAY': new Date(year, 10, 1),
                    'ARMISCTICE_DAY': new Date(year, 10, 11),
                    'CHRISTMAS': new Date(year, 11, 25),
                }


                let dateString = ""
                if (exception.holliday && exception.holliday !== '') {
                    let year = holidays[exception.holliday].getFullYear();
                    let month = String(holidays[exception.holliday].getMonth() + 1).padStart(2, '0');
                    let day = String(holidays[exception.holliday].getDate()).padStart(2, '0');
                    dateString = `${year}-${month}-${day}`;
                }
                else {
                    dateString = exception.date;
                }

                const date = new Date(dateString);

                const startOfWeek = new Date(today);
                startOfWeek.setDate(startOfWeek.getDate() - (startOfWeek.getDay() + 6) % 7);

                // Generate a list of all the dates in the current week
                const datesInWeek = [];
                for (let i = 0; i < 7; i++) {
                    const date = new Date(startOfWeek.getTime());
                    date.setDate(date.getDate() + i);
                    datesInWeek.push(date);
                }

                // Format the dates as strings in the format "yyyy-mm-dd"
                const formattedDatesInWeek = datesInWeek.map(date => {
                    const year = date.getFullYear();
                    const month = String(date.getMonth() + 1).padStart(2, '0');
                    const day = String(date.getDate()).padStart(2, '0');
                    return `${year}-${month}-${day}`;
                });
                let isWithinCurrentWeek = formattedDatesInWeek.includes(dateString)

                const dayOfWeek = date.getDay(); // 0 is Sunday, 1 is Monday, and so on
                const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
                const dayOfWeekStr = days[dayOfWeek];

                if (isWithinCurrentWeek) {
                    dayVariables[dayOfWeekStr.toLowerCase()] = check_record(exception)
                    exception_days.push(dayOfWeekStr.toLowerCase())
                }


            })
            data.push({
                exception_days: exception_days,
                title: timetable_title,
                is_24_7: item.is_24_7 ? item.is_24_7 : false,
                appointment_only: item.appointment_only,
                message: item.messages ? item.messages.map((mess) => (
                    mess.message
                )) : "",
                week:
                    item.is_24_7 ?
                        {
                            monday: dayVariables.monday !== "" ? dayVariables.monday : record_24_7,
                            tuesday: dayVariables.tuesday !== "" ? dayVariables.tuesday : record_24_7,
                            wednesday: dayVariables.wednesday !== "" ? dayVariables.wednesday : record_24_7,
                            thursday: dayVariables.thursday !== "" ? dayVariables.thursday : record_24_7,
                            friday: dayVariables.friday !== "" ? dayVariables.friday : record_24_7,
                            saturday: dayVariables.saturday !== "" ? dayVariables.saturday : record_24_7,
                            sunday: dayVariables.sunday !== "" ? dayVariables.sunday : record_24_7
                        }
                        :
                        {
                            monday: dayVariables.monday ? dayVariables.monday : default_record ? default_record : check_record('closed'),
                            tuesday: dayVariables.tuesday ? dayVariables.tuesday : default_record ? default_record : check_record('closed'),
                            wednesday: dayVariables.wednesday ? dayVariables.wednesday : default_record ? default_record : check_record('closed'),
                            thursday: dayVariables.thursday ? dayVariables.thursday : default_record ? default_record : check_record('closed'),
                            friday: dayVariables.friday ? dayVariables.friday : default_record ? default_record : check_record('closed'),
                            saturday: dayVariables.saturday ? dayVariables.saturday : default_record ? default_record : check_record('closed'),
                            sunday: dayVariables.sunday ? dayVariables.sunday : default_record ? default_record : check_record('closed'),
                        }
            });




        })
    }
    if (json.enterprise_timetables.length > 0) {
        let exception_days = []
        let dayVariables = {
            monday: "",
            tuesday: "",
            wednesday: "",
            thursday: "",
            friday: "",
            saturday: "",
            sunday: "",
        }
        let monday = ""
        let tuesday = ""
        let wednesday = ""
        let thursday = ""
        let friday = ""
        let saturday = ""
        let sunday = ""

        json.enterprise_timetables.forEach(timetable => {
            let item = timetable.timetable

            let check_record = (x) => {
                let oh = {
                    hours: [],
                    items: [],
                    message: [],
                    desciption: '',
                    is_closed: false,
                    appointment_only: false
                }
                if (x === 'closed' || x.closed) {
                    oh.is_closed = true;
                    if (x.message) {
                        oh.message = [{ title: "", message: x.message }]
                    }
                }
                if (x !== 'closed' && x.timeslots && x.timeslots.length > 0) {
                    x.timeslots.forEach((timeslot) => {
                        if (timeslot.message && timeslot.message.message && x.timeslots.length > 1) {
                            let y = {
                                from: timeslot.from_time,
                                to: timeslot.to_time,
                                title: '',
                                desc: '',
                                link: ''
                            }
                            y.title = timeslot.message.title ? timeslot.message.title : ''
                            y.desc = timeslot.message.message ? timeslot.message.message : ''
                            oh.items.push(y)

                        }
                        else if (timeslot.message && timeslot.message.message) {
                            let y = {
                                from: timeslot.from_time,
                                to: timeslot.to_time,
                                title: '',
                                desc: '',
                                link: ''
                            }
                            y.title = timeslot.message.title ? timeslot.message.title : ''
                            y.desc = timeslot.message.message ? timeslot.message.message : ''
                            oh.items.push(y)
                            oh.hours.push(y)
                        }
                        else {
                            oh.hours.push({ from: timeslot.from_time, to: timeslot.to_time, title: timeslot.message ? timeslot.message.title : '', desc: timeslot.message ? timeslot.message.message : '' })
                            oh.message = x.messages
                        }

                    })
                }
                else if (x !== 'closed' && !x.closed && x.exception_timeslots && x.exception_timeslots.length > 0) {
                    if (x.message) {
                        oh.message = [{ title: "", message: x.message }]
                    }
                    x.exception_timeslots.reverse().forEach((timeslot) => {
                        if (timeslot.message && timeslot.message.message && x.exception_timeslots.length > 1) {
                            let y = {
                                from: timeslot.from_time,
                                to: timeslot.to_time,
                                title: '',
                                desc: '',
                                link: ''
                            }
                            y.title = timeslot.message.title ? timeslot.message.title : ''
                            y.desc = timeslot.message.message ? timeslot.message.message : ''
                            oh.items.push(y)
                            oh.hours.push(y)

                        }
                        else if (timeslot.message && timeslot.message.message) {
                            let y = {
                                from: timeslot.from_time,
                                to: timeslot.to_time,
                                title: '',
                                desc: '',
                                link: ''
                            }
                            y.title = timeslot.message.title ? timeslot.message.title : ''
                            y.desc = timeslot.message.message ? timeslot.message.message : ''
                            oh.items.push(y)
                            oh.hours.push(y)
                        }
                        else {
                            oh.hours.push({ from: timeslot.from_time, to: timeslot.to_time, title: '', desc: '' })
                            if (x.message) {
                                oh.message = [{ title: "", message: x.message }]
                            }
                            // oh.message = x.messages
                        }
                    })
                }
                else if (!x.closed) {
                    oh.message = x.message && x.message.descriptions.map(message => (
                        message.language === lang ? message.description : ''
                    ))
                }
                return oh
            }

            let default_record = ""

            item.records.forEach((record) => {
                for (let i = 0; i < record.valid_days.length; i++) {
                    let day = record.valid_days[i]
                    if (day === '0') { default_record = record.timeslots.length > 0 ? check_record(record) : check_record('closed') }
                    if (day === '1') { dayVariables.monday = record.timeslots.length > 0 ? check_record(record) : check_record('closed') }
                    if (day === '2') { dayVariables.tuesday = record.timeslots.length > 0 ? check_record(record) : check_record('closed') }
                    if (day === '3') { dayVariables.wednesday = record.timeslots.length > 0 ? check_record(record) : check_record('closed') }
                    if (day === '4') { dayVariables.thursday = record.timeslots.length > 0 ? check_record(record) : check_record('closed') }
                    if (day === '5') { dayVariables.friday = record.timeslots.length > 0 ? check_record(record) : check_record('closed') }
                    if (day === '6') { dayVariables.saturday = record.timeslots.length > 0 ? check_record(record) : check_record('closed') }
                    if (day === '7') { dayVariables.sunday = record.timeslots.length > 0 ? check_record(record) : check_record('closed') }

                }
            })
            let timetable_title = ''
            if (item.timetable_type) {
                timetable_title = item.timetable_type.title
            } else { timetable_title = translate('opening_hours', lang) }

            let record_24_7 = {
                "hours": [
                    {
                        "from": "00:00:00",
                        "to": "23:59:59"
                    }
                ],
                "items": [],
                "message": null,
                "desciption": "",
                "is_closed": false,
                "appointment_only": false
            }

            item.day_exception.map((exception) => {
                function getEasterDate(year) {
                    // This implementation of the algorithm to calculate the date of Easter in Western Christianity is based on the Meeus/Jones/Butcher algorithm
                    // Source: https://en.wikipedia.org/wiki/Computus#Anonymous_Gregorian_algorithm
                    let a = year % 19;
                    let b = Math.floor(year / 100);
                    let c = year % 100;
                    let d = Math.floor(b / 4);
                    let e = b % 4;
                    let f = Math.floor((b + 8) / 25);
                    let g = Math.floor((b - f + 1) / 3);
                    let h = (19 * a + b - d - g + 15) % 30;
                    let i = Math.floor(c / 4);
                    let k = c % 4;
                    let L = (32 + 2 * e + 2 * i - h - k) % 7;
                    let m = Math.floor((a + 11 * h + 22 * L) / 451);
                    let month = Math.floor((h + L - 7 * m + 114) / 31);
                    let day = ((h + L - 7 * m + 114) % 31) + 1;

                    return new Date(year, month - 1, day);
                }

                function addDaysToDate(date, days) {
                    let newDate = new Date(date);
                    newDate.setDate(newDate.getDate() + days);
                    return newDate;
                }
                const today = new Date();
                let year = today.getFullYear();
                let easterDate = getEasterDate(year)
                let holidays = {
                    'EASTER': getEasterDate(year),
                    'NEW_YEAR': new Date(year, 0, 1),
                    'EASTER_MONDAY': addDaysToDate(easterDate, 1),
                    'LABOUR_DAY': new Date(year, 4, 1),
                    'ASCENSION_DAY': addDaysToDate(easterDate, 39),
                    'WHIT_MONDAY': addDaysToDate(easterDate, 50),
                    'PENTECOST': addDaysToDate(easterDate, 49),
                    'NATIONAL_HOLLIDAY': new Date(year, 6, 21),
                    'ASSUMPTION_OF_MARY': new Date(year, 7, 15),
                    'ALL_SAINTS_DAY': new Date(year, 10, 1),
                    'ARMISCTICE_DAY': new Date(year, 10, 11),
                    'CHRISTMAS': new Date(year, 11, 25),
                }

                let dateString = ""
                if (exception.holliday && exception.holliday !== '') {
                    let year = holidays[exception.holliday].getFullYear();
                    let month = String(holidays[exception.holliday].getMonth() + 1).padStart(2, '0');
                    let day = String(holidays[exception.holliday].getDate()).padStart(2, '0');
                    dateString = `${year}-${month}-${day}`;
                }
                else {
                    dateString = exception.date;
                }

                const date = new Date(dateString);

                const startOfWeek = new Date(today);
                startOfWeek.setDate(startOfWeek.getDate() - (startOfWeek.getDay() + 6) % 7);

                // Generate a list of all the dates in the current week
                const datesInWeek = [];
                for (let i = 0; i < 7; i++) {
                    const date = new Date(startOfWeek.getTime());
                    date.setDate(date.getDate() + i);
                    datesInWeek.push(date);
                }

                // Format the dates as strings in the format "yyyy-mm-dd"
                const formattedDatesInWeek = datesInWeek.map(date => {
                    const year = date.getFullYear();
                    const month = String(date.getMonth() + 1).padStart(2, '0');
                    const day = String(date.getDate()).padStart(2, '0');
                    return `${year}-${month}-${day}`;
                });
                let isWithinCurrentWeek = formattedDatesInWeek.includes(dateString)

                const dayOfWeek = date.getDay(); // 0 is Sunday, 1 is Monday, and so on
                const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
                const dayOfWeekStr = days[dayOfWeek];

                if (isWithinCurrentWeek) {
                    dayVariables[dayOfWeekStr.toLowerCase()] = check_record(exception)
                    exception_days.push(dayOfWeekStr.toLowerCase())
                }


            })


            data.push({
                exception_days: exception_days,
                title: timetable_title,
                is_24_7: item.is_24_7 ? item.is_24_7 : false,
                appointment_only: item.appointment_only,
                message: item.messages ? item.messages.map((mess) => (
                    mess.message
                )) : "",
                week:
                    item.is_24_7 ?
                        {
                            monday: dayVariables.monday !== "" ? dayVariables.monday : record_24_7,
                            tuesday: dayVariables.tuesday !== "" ? dayVariables.tuesday : record_24_7,
                            wednesday: dayVariables.wednesday !== "" ? dayVariables.wednesday : record_24_7,
                            thursday: dayVariables.thursday !== "" ? dayVariables.thursday : record_24_7,
                            friday: dayVariables.friday !== "" ? dayVariables.friday : record_24_7,
                            saturday: dayVariables.saturday !== "" ? dayVariables.saturday : record_24_7,
                            sunday: dayVariables.sunday !== "" ? dayVariables.sunday : record_24_7
                        }
                        :
                        {
                            monday: dayVariables.monday ? dayVariables.monday : default_record ? default_record : check_record('closed'),
                            tuesday: dayVariables.tuesday ? dayVariables.tuesday : default_record ? default_record : check_record('closed'),
                            wednesday: dayVariables.wednesday ? dayVariables.wednesday : default_record ? default_record : check_record('closed'),
                            thursday: dayVariables.thursday ? dayVariables.thursday : default_record ? default_record : check_record('closed'),
                            friday: dayVariables.friday ? dayVariables.friday : default_record ? default_record : check_record('closed'),
                            saturday: dayVariables.saturday ? dayVariables.saturday : default_record ? default_record : check_record('closed'),
                            sunday: dayVariables.sunday ? dayVariables.sunday : default_record ? default_record : check_record('closed'),
                        }
            });




        })
    }
    if (json.group_timetables.length > 0) {
        let exception_days = []
        let dayVariables = {
            monday: "",
            tuesday: "",
            wednesday: "",
            thursday: "",
            friday: "",
            saturday: "",
            sunday: "",
        }
        let monday = ""
        let tuesday = ""
        let wednesday = ""
        let thursday = ""
        let friday = ""
        let saturday = ""
        let sunday = ""

        json.group_timetables.forEach(timetable => {
            let item = timetable.timetable

            let check_record = (x) => {
                let oh = {
                    hours: [],
                    items: [],
                    message: [],
                    desciption: '',
                    is_closed: false,
                    appointment_only: false
                }
                if (x === 'closed' || x.closed) {
                    oh.is_closed = true;
                    if (x.message) {
                        oh.message = [{ title: "", message: x.message }]
                    }
                }
                if (x !== 'closed' && x.timeslots && x.timeslots.length > 0) {
                    x.timeslots.forEach((timeslot) => {
                        if (timeslot.message && timeslot.message.message && x.timeslots.length > 1) {
                            let y = {
                                from: timeslot.from_time,
                                to: timeslot.to_time,
                                title: '',
                                desc: '',
                                link: ''
                            }
                            y.title = timeslot.message.title ? timeslot.message.title : ''
                            y.desc = timeslot.message.message ? timeslot.message.message : ''
                            oh.items.push(y)

                        }
                        else if (timeslot.message && timeslot.message.message) {
                            let y = {
                                from: timeslot.from_time,
                                to: timeslot.to_time,
                                title: '',
                                desc: '',
                                link: ''
                            }
                            y.title = timeslot.message.title ? timeslot.message.title : ''
                            y.desc = timeslot.message.message ? timeslot.message.message : ''
                            oh.items.push(y)
                            oh.hours.push(y)
                        }
                        else {
                            oh.hours.push({ from: timeslot.from_time, to: timeslot.to_time, title: timeslot.message ? timeslot.message.title : '', desc: timeslot.message ? timeslot.message.message : '' })
                            oh.message = x.messages
                        }

                    })
                }
                else if (x !== 'closed' && !x.closed && x.exception_timeslots && x.exception_timeslots.length > 0) {
                    if (x.message) {
                        oh.message = [{ title: "", message: x.message }]
                    }
                    x.exception_timeslots.reverse().forEach((timeslot) => {
                        if (timeslot.message && timeslot.message.message && x.exception_timeslots.length > 1) {
                            let y = {
                                from: timeslot.from_time,
                                to: timeslot.to_time,
                                title: '',
                                desc: '',
                                link: ''
                            }
                            y.title = timeslot.message.title ? timeslot.message.title : ''
                            y.desc = timeslot.message.message ? timeslot.message.message : ''
                            oh.items.push(y)
                            oh.hours.push(y)

                        }
                        else if (timeslot.message && timeslot.message.message) {
                            let y = {
                                from: timeslot.from_time,
                                to: timeslot.to_time,
                                title: '',
                                desc: '',
                                link: ''
                            }
                            y.title = timeslot.message.title ? timeslot.message.title : ''
                            y.desc = timeslot.message.message ? timeslot.message.message : ''
                            oh.items.push(y)
                            oh.hours.push(y)
                        }
                        else {
                            oh.hours.push({ from: timeslot.from_time, to: timeslot.to_time, title: '', desc: '' })
                            if (x.message) {
                                oh.message = [{ title: "", message: x.message }]
                            }
                            // oh.message = x.messages
                        }
                    })
                }
                else if (!x.closed) {
                    oh.message = x.message && x.message.descriptions.map(message => (
                        message.language === lang ? message.description : ''
                    ))
                }
                return oh
            }

            let default_record = ""

            item.records.forEach((record) => {
                for (let i = 0; i < record.valid_days.length; i++) {
                    let day = record.valid_days[i]
                    if (day === '0') { default_record = record.timeslots.length > 0 ? check_record(record) : check_record('closed') }
                    if (day === '1') { dayVariables.monday = record.timeslots.length > 0 ? check_record(record) : check_record('closed') }
                    if (day === '2') { dayVariables.tuesday = record.timeslots.length > 0 ? check_record(record) : check_record('closed') }
                    if (day === '3') { dayVariables.wednesday = record.timeslots.length > 0 ? check_record(record) : check_record('closed') }
                    if (day === '4') { dayVariables.thursday = record.timeslots.length > 0 ? check_record(record) : check_record('closed') }
                    if (day === '5') { dayVariables.friday = record.timeslots.length > 0 ? check_record(record) : check_record('closed') }
                    if (day === '6') { dayVariables.saturday = record.timeslots.length > 0 ? check_record(record) : check_record('closed') }
                    if (day === '7') { dayVariables.sunday = record.timeslots.length > 0 ? check_record(record) : check_record('closed') }

                }
            })
            let timetable_title = ''
            if (item.timetable_type) {
                timetable_title = item.timetable_type.title
            } else { timetable_title = translate('opening_hours', lang) }

            let record_24_7 = {
                "hours": [
                    {
                        "from": "00:00:00",
                        "to": "23:59:59"
                    }
                ],
                "items": [],
                "message": null,
                "desciption": "",
                "is_closed": false,
                "appointment_only": false
            }

            item.day_exception.map((exception) => {
                function getEasterDate(year) {
                    // This implementation of the algorithm to calculate the date of Easter in Western Christianity is based on the Meeus/Jones/Butcher algorithm
                    // Source: https://en.wikipedia.org/wiki/Computus#Anonymous_Gregorian_algorithm
                    let a = year % 19;
                    let b = Math.floor(year / 100);
                    let c = year % 100;
                    let d = Math.floor(b / 4);
                    let e = b % 4;
                    let f = Math.floor((b + 8) / 25);
                    let g = Math.floor((b - f + 1) / 3);
                    let h = (19 * a + b - d - g + 15) % 30;
                    let i = Math.floor(c / 4);
                    let k = c % 4;
                    let L = (32 + 2 * e + 2 * i - h - k) % 7;
                    let m = Math.floor((a + 11 * h + 22 * L) / 451);
                    let month = Math.floor((h + L - 7 * m + 114) / 31);
                    let day = ((h + L - 7 * m + 114) % 31) + 1;

                    return new Date(year, month - 1, day);
                }

                function addDaysToDate(date, days) {
                    let newDate = new Date(date);
                    newDate.setDate(newDate.getDate() + days);
                    return newDate;
                }
                const today = new Date();
                let year = today.getFullYear();
                let easterDate = getEasterDate(year)
                let holidays = {
                    'EASTER': getEasterDate(year),
                    'NEW_YEAR': new Date(year, 0, 1),
                    'EASTER_MONDAY': addDaysToDate(easterDate, 1),
                    'LABOUR_DAY': new Date(year, 4, 1),
                    'ASCENSION_DAY': addDaysToDate(easterDate, 39),
                    'WHIT_MONDAY': addDaysToDate(easterDate, 50),
                    'PENTECOST': addDaysToDate(easterDate, 49),
                    'NATIONAL_HOLLIDAY': new Date(year, 6, 21),
                    'ASSUMPTION_OF_MARY': new Date(year, 7, 15),
                    'ALL_SAINTS_DAY': new Date(year, 10, 1),
                    'ARMISCTICE_DAY': new Date(year, 10, 11),
                    'CHRISTMAS': new Date(year, 11, 25),
                }

                let dateString = ""
                if (exception.holliday && exception.holliday !== '') {
                    let year = holidays[exception.holliday].getFullYear();
                    let month = String(holidays[exception.holliday].getMonth() + 1).padStart(2, '0');
                    let day = String(holidays[exception.holliday].getDate()).padStart(2, '0');
                    dateString = `${year}-${month}-${day}`;
                }
                else {
                    dateString = exception.date;
                }

                const date = new Date(dateString);

                const startOfWeek = new Date(today);
                startOfWeek.setDate(startOfWeek.getDate() - (startOfWeek.getDay() + 6) % 7);

                // Generate a list of all the dates in the current week
                const datesInWeek = [];
                for (let i = 0; i < 7; i++) {
                    const date = new Date(startOfWeek.getTime());
                    date.setDate(date.getDate() + i);
                    datesInWeek.push(date);
                }

                // Format the dates as strings in the format "yyyy-mm-dd"
                const formattedDatesInWeek = datesInWeek.map(date => {
                    const year = date.getFullYear();
                    const month = String(date.getMonth() + 1).padStart(2, '0');
                    const day = String(date.getDate()).padStart(2, '0');
                    return `${year}-${month}-${day}`;
                });
                let isWithinCurrentWeek = formattedDatesInWeek.includes(dateString)

                const dayOfWeek = date.getDay(); // 0 is Sunday, 1 is Monday, and so on
                const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
                const dayOfWeekStr = days[dayOfWeek];

                if (isWithinCurrentWeek) {
                    dayVariables[dayOfWeekStr.toLowerCase()] = check_record(exception)
                    exception_days.push(dayOfWeekStr.toLowerCase())
                }


            })


            data.push({
                exception_days: exception_days,
                title: timetable_title,
                is_24_7: item.is_24_7 ? item.is_24_7 : false,
                appointment_only: item.appointment_only,
                message: item.messages ? item.messages.map((mess) => (
                    mess.message
                )) : "",
                week:
                    item.is_24_7 ?
                        {
                            monday: dayVariables.monday !== "" ? dayVariables.monday : record_24_7,
                            tuesday: dayVariables.tuesday !== "" ? dayVariables.tuesday : record_24_7,
                            wednesday: dayVariables.wednesday !== "" ? dayVariables.wednesday : record_24_7,
                            thursday: dayVariables.thursday !== "" ? dayVariables.thursday : record_24_7,
                            friday: dayVariables.friday !== "" ? dayVariables.friday : record_24_7,
                            saturday: dayVariables.saturday !== "" ? dayVariables.saturday : record_24_7,
                            sunday: dayVariables.sunday !== "" ? dayVariables.sunday : record_24_7
                        }
                        :
                        {
                            monday: dayVariables.monday ? dayVariables.monday : default_record ? default_record : check_record('closed'),
                            tuesday: dayVariables.tuesday ? dayVariables.tuesday : default_record ? default_record : check_record('closed'),
                            wednesday: dayVariables.wednesday ? dayVariables.wednesday : default_record ? default_record : check_record('closed'),
                            thursday: dayVariables.thursday ? dayVariables.thursday : default_record ? default_record : check_record('closed'),
                            friday: dayVariables.friday ? dayVariables.friday : default_record ? default_record : check_record('closed'),
                            saturday: dayVariables.saturday ? dayVariables.saturday : default_record ? default_record : check_record('closed'),
                            sunday: dayVariables.sunday ? dayVariables.sunday : default_record ? default_record : check_record('closed'),
                        }
            });




        })
    }
    return data;
}

export default OpeninghoursModel;